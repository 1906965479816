import Link from 'next/link';
import { useRouter } from 'next/router';
import Typography from '@mui/material/Typography';

const LinkButton = ({ pathname, text }: { pathname: string; text: string }) => {
    const {
        query: { lang },
    } = useRouter();
    return (
        <Link href={{ pathname, query: { lang } }} passHref>
            <Typography color="primary" sx={{ '&:hover': { textDecoration: 'underline' } }}>
                {text}
            </Typography>
        </Link>
    );
};

export default LinkButton;
