import { useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import * as Yup from 'yup';
import type { FormikHelpers, FieldProps } from 'formik';
import { Field, useFormik, FormikProvider } from 'formik';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryButton } from '@/components/common';
import { routes } from '@/constants/routes';
import { useTranslation } from '@/hooks/translations';
import AuthService from '@/services/auth';
import LinkButton from '@/components/LinkButton';

import styles from './index.module.scss';

interface ResetPasswordForm {
    email: string;
}

export default function ResetPassword() {
    const authService = AuthService.getInstance();
    const { t } = useTranslation('common');
    const [error, setError] = useState<string>('');
    const {
        push,
        query: { email },
    } = useRouter();

    const initialValues = useMemo<ResetPasswordForm>(
        () => ({
            email: (email as string | undefined) || '',
        }),
        [email],
    );

    const validationSchema = Yup.object({
        email: Yup.string().email(t('Please enter a valid email')).required(t('The email field is required!')),
    });

    const formik = useFormik<ResetPasswordForm>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values: ResetPasswordForm, { setSubmitting }: FormikHelpers<ResetPasswordForm>) => {
            setSubmitting(true);
            authService
                .resetPassword(values.email)
                .then((response) => {
                    const { data } = response;
                    push({
                        pathname: routes.RESET_PASSWORD_VERIFY,
                        query: { email: values.email, token: data?.data?.token || '' },
                    });
                })
                .catch((e) => {
                    let message = t('Something went wrong');
                    if (e.message === 'USER_NOT_FOUND') message = t('User not found');
                    setError(message);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const { handleReset, handleSubmit } = formik;

    return (
        <Box className={styles.resetPasswordContainer}>
            <Card className={styles.resetCard}>
                <CardContent>
                    <FormikProvider value={formik}>
                        <form onSubmit={handleSubmit} onReset={handleReset}>
                            <Typography color="primary" className={styles.headerText} variant="h6">
                                {t('QlubPay Reset Password')}
                            </Typography>
                            <Field name="email">
                                {({ field, meta }: FieldProps) => (
                                    <TextField
                                        fullWidth
                                        label={t('Email')}
                                        required
                                        type="text"
                                        id="email"
                                        margin="normal"
                                        {...field}
                                        error={!!meta.error}
                                        helperText={meta.error}
                                    />
                                )}
                            </Field>
                            {/* reset password submit button */}
                            {error && (
                                <Typography textAlign="center" color="error">
                                    {error}
                                </Typography>
                            )}
                            <PrimaryButton
                                id="login-btn"
                                type="submit"
                                text={t('Send Email')}
                                fullWidth
                                styles={{
                                    marginTop: '1rem',
                                    fontWeight: 700,
                                    fontSize: '1rem',
                                    borderRadius: '0.5rem',
                                }}
                            />
                            <Grid item className={styles.linksContainer}>
                                <LinkButton
                                    pathname={routes.RESET_PASSWORD_VERIFY}
                                    text={t('Already have verification code?')}
                                />
                                <LinkButton pathname={routes.LOGIN} text={t('Go to Login')} />
                            </Grid>
                        </form>
                    </FormikProvider>
                </CardContent>
            </Card>
        </Box>
    );
}
