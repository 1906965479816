import { useTranslation } from '@/hooks/translations';

export const useEntryText = () => {
    const { t } = useTranslation('common');
    const chars = '(! # $ % * + - . : ; ? @)';

    const getEntryText = (entry: [string, number | boolean]) => {
        let text = '';
        switch (entry[0]) {
            case 'max':
                text = t('Maximum characters is allowed', { max: entry[1] });
                break;
            case 'min':
                text = t('Minimum characters is allowed', { min: entry[1] });
                break;
            case 'number':
                text = t('At least one number is required');
                break;
            case 'smallcase':
                text = t('At least one smallcase letter is required');
                break;
            case 'specialCharacter':
                text = t('At least one special character is required', { chars });
                break;
            case 'uppercase':
                text = t('At least one uppercase letter is required');
                break;
            default:
                break;
        }

        return text;
    };

    return { getEntryText };
};

export const useEntryCondition = (value: string) => {
    const checkEntryConditionFullfilled = (entry: [string, number | boolean]) => {
        let conditionFullfilled = false;
        switch (entry[0]) {
            case 'max':
                if (value?.length <= (entry[1] as number)) conditionFullfilled = true;
                break;
            case 'min':
                if (value?.length >= (entry[1] as number)) conditionFullfilled = true;
                break;
            case 'number':
                if (/\d/.test(value)) conditionFullfilled = true;
                break;
            case 'smallcase':
                if (value.toUpperCase() !== value) conditionFullfilled = true;
                break;
            case 'specialCharacter':
                if (/\W+/.test(value)) conditionFullfilled = true;
                break;
            case 'uppercase':
                if (value.toLowerCase() !== value) conditionFullfilled = true;
                break;
            default:
                break;
        }

        return conditionFullfilled;
    };

    return { checkEntryConditionFullfilled };
};
