import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';

import FormikTextField from '@/components/Formik/FormikTextField';
import LinkButton from '@/components/LinkButton';
import { routes } from '@/constants/routes';
import { PrimaryButton } from '@/components/common';
import { recaptchaSiteKey } from '@/constants/configs';
import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import AuthService from '@/services/auth';
import type { IBaseLoginProps } from '@/views/Login/login';
import { disablePasswordLogin } from '@/views/Login/index';

interface IPasswordLoginProps extends IBaseLoginProps {}

const PasswordLogin = ({
    setLoading,
    setLoginAsAdmin,
    setLoginResponse,
    setPage,
    accessSelectRestaurant,
    setError,
    loginAsAdmin,
}: IPasswordLoginProps) => {
    const { t } = useTranslation('common');
    const authService = AuthService.getInstance();
    const { enqueueSnackbar } = useSnackbar();

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(t('Invalid email ID')).required(t('Email is required')),
            password: Yup.string().required(t('Password is required')),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setError('');
            setLoading(true);
            recaptchaRef.current
                ?.executeAsync()
                .then((value) => {
                    return authService
                        .loginV2({ ...values, type: loginAsAdmin ? 'admin' : 'vendor', recaptcha: value || '' })
                        .then((res) => {
                            onPushEvent('user_successfully_logged_in');
                            setLoginResponse(res);
                            if (res.data.challengeName) {
                                setPage?.('mfa-code');
                                // } else if (localStorage.getItem(isFirstLoginKey) !== form.email) {
                            } else {
                                accessSelectRestaurant(res);
                            }
                        })
                        .catch(() => {
                            onPushEvent('user_login_failed');
                            enqueueSnackbar(t('Incorrect credentials'), { variant: 'error' });
                        });
                })
                .finally(() => {
                    setSubmitting(false);
                    setLoading(false);
                });
        },
    });

    const { handleReset, handleSubmit, isSubmitting } = formik;

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginAsAdmin(event.target.checked);
    };

    return (
        <FormikProvider value={formik}>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormikTextField name={'email'} label={t('Email')} type="email" required fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField name={'password'} label={t('Password')} type="password" required fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {!disablePasswordLogin && (
                                <FormControlLabel
                                    control={<Checkbox checked={loginAsAdmin} onChange={handleRoleChange} />}
                                    label={t('Login as admin')}
                                />
                            )}
                            <Box sx={{ flex: '1 1' }} />
                            <LinkButton pathname={routes.RESET_PASSWORD} text={t('Forgot Password?')} />
                        </Box>
                    </Grid>
                </Grid>
                <PrimaryButton
                    id="login-btn"
                    disabled={isSubmitting}
                    type="submit"
                    text={isSubmitting ? t('Logging in') : t('Login')}
                    fullWidth
                    styles={{
                        marginTop: '1rem',
                        fontWeight: 700,
                        fontSize: '1rem',
                        borderRadius: '0.5rem',
                    }}
                />
            </form>
            {recaptchaSiteKey && <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />}
        </FormikProvider>
    );
};

export default PasswordLogin;
