import { disablePasswordLogin } from '@/views/Login/index';
import { Button, Grid } from '@mui/material';
import GoogleButton from 'react-google-button';
import { onPushEvent } from '@/services/lib/gtm';
import { useCallback, useMemo, useRef } from 'react';
import AuthService from '@/services/auth';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { recaptchaSiteKey } from '@/constants/configs';
import ReCAPTCHA from 'react-google-recaptcha';
import { IBaseLoginProps } from '@/views/Login/login';
import { useTranslation } from '@/hooks/translations';

const ssoPathList = ['/google', '/sso/[provider]'];

export const hasSSOLogin = (pathname: string) => {
    return disablePasswordLogin && ssoPathList.includes(pathname);
};

interface ISSOLoginProps extends IBaseLoginProps {}

const SSOLogin = ({
    loading,
    setLoading,
    setLoginAsAdmin,
    setLoginResponse,
    setPage,
    accessSelectRestaurant,
}: ISSOLoginProps) => {
    const { t } = useTranslation('common');
    const authService = AuthService.getInstance();
    const router = useRouter();
    const { code, provider: routerProvider } = router.query as { code?: string; provider?: string };
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const provider = routerProvider || 'google';

    const ssoHandler = (ssoProvider: string) => () => {
        setLoading(true);
        recaptchaRef.current?.executeAsync().then((token) => {
            (ssoProvider === 'google'
                ? authService.ssoGoogleLink(token || '')
                : authService.ssoLink(ssoProvider, token || '')
            )
                .then((res) => {
                    window.location.href = res.data;
                })
                .catch((err: any) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    const ssoLoginHandler = useCallback(
        (oauthToken: string) => {
            if (loading) {
                return;
            }

            const getCallbackUrl = () => {
                if (ssoPathList.indexOf(router.pathname) === 0) {
                    return ssoPathList[0];
                }
                if (ssoPathList.indexOf(router.pathname) === 1) {
                    return ssoPathList[1].replace('[provider]', provider || '');
                }
                return '/google';
            };

            router.replace(getCallbackUrl());

            setLoading(true);
            setLoginAsAdmin(true);
            (provider === 'google'
                ? authService.ssoGoogleLogin({
                      code: oauthToken,
                  })
                : authService.ssoLogin(provider || '', {
                      code: oauthToken,
                  })
            )
                ?.then((res: any) => {
                    router.replace('/');
                    onPushEvent('user_successfully_logged_in');
                    setLoginResponse(res);
                    if (res.data.challengeName) {
                        setPage?.('mfa-code');
                    } else if (provider === 'google') {
                        accessSelectRestaurant(res, { forceAdmin: provider === 'google' });
                    } else {
                        accessSelectRestaurant(res, { userProvider: provider });
                    }
                })
                .catch((err: any) => {
                    enqueueSnackbar(err.message, { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [loading, provider],
    );

    useMemo(() => {
        if (!code || !router.isReady) {
            return;
        }

        ssoLoginHandler(code);
    }, [code, router.isReady]);

    const content = useMemo(() => {
        switch (provider) {
            default:
            case 'google':
                return <GoogleButton disabled={loading} onClick={ssoHandler('google')} />;
            case 'ping-identity':
                return (
                    <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        disabled={loading}
                        onClick={ssoHandler('ping-identity')}
                        sx={{ textTransform: 'none' }}
                    >
                        {t('Login with Ping Identity')}
                    </Button>
                );
        }
    }, [provider]);

    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                sx={{
                    minWidth: '300px',
                    ' > div': {
                        width: '100% !important',
                    },
                }}
            >
                {content}
            </Grid>
            {recaptchaSiteKey && <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />}
        </Grid>
    );
};

export default SSOLogin;
