import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, Grid, SxProps, Typography } from '@mui/material';

import {
    accessTokenKey,
    isFirstLoginKey,
    qlubLoginModeKey,
    refreshTokenKey,
    tokenKey,
    userIdKey,
    userNameKey,
    userRoleKey,
} from '@/config/axios';
import { useTranslation } from '@/hooks/translations';
import { Logo } from '@/components/icon/logo';
import { LoginResponse } from '@/interfaces/auth/reponse';

import LanguageSelector from '@/components/NavBar/LanguageSelector';
import LoginComponent from './login';
import SelectRestaurant from './select-restaurant';
import Enable from './enable';
import Mfa from './mfa';

type PageType = 'login' | 'mfa-code' | 'mfa-enable';

export const disablePasswordLogin = Boolean(process.env.NEXT_PUBLIC_DISABLE_PASSWORD_LOGIN || false);

export type ILoginProps = {
    loading: boolean;
    loginAsAdmin?: boolean;
    setLoading: (loading: boolean) => void;
    setError: (error: string) => void;
    setPage?: (page: PageType) => void;
    accessSelectRestaurant: (
        res: LoginResponse,
        options?: {
            forceAdmin?: boolean;
            userProvider?: string;
        },
    ) => void;
};

const TypographyProps: SxProps = {
    textAlign: 'center',
    backgroundColor: 'primary.light',
    padding: '0.8rem',
    marginBottom: '1rem',
    borderRadius: '0.3rem',
    fontWeight: 700,
};

const Login = () => {
    const { t } = useTranslation('common');

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const [loginAsAdmin, setLoginAsAdmin] = useState<boolean>(false);
    const [loginResponse, setLoginResponse] = useState<LoginResponse>();

    const [selectRestaurant, setSelectRestaurant] = useState<boolean>(false);
    const [page, setPage] = useState<PageType>('login');

    useEffect(() => {
        if (localStorage.getItem(accessTokenKey) && localStorage.getItem(refreshTokenKey)) {
            setSelectRestaurant(true);
        }
    }, []);

    useEffect(() => {
        setError('');
    }, [page]);

    const accessSelectRestaurant = (
        { data }: LoginResponse,
        options?: {
            forceAdmin?: boolean;
            userProvider?: string;
        },
    ) => {
        localStorage.setItem(tokenKey, data.access_token);
        localStorage.setItem(refreshTokenKey, data.refreshToken);
        localStorage.setItem(
            qlubLoginModeKey,
            options?.forceAdmin || loginAsAdmin
                ? 'admin'
                : options?.userProvider
                ? `vendor:${options.userProvider}`
                : 'vendor',
        );
        localStorage.setItem(isFirstLoginKey, data.email);
        localStorage.setItem(userIdKey, data.userId || data.id);
        localStorage.setItem(accessTokenKey, data.token);
        localStorage.setItem(userNameKey, data.name || '');
        localStorage.setItem(userRoleKey, data.role || '');

        setSelectRestaurant(true);
    };

    const getTitle = () => {
        if (selectRestaurant) {
            return t('Select Your Restaurant');
        }
        switch (page) {
            default:
            case 'login':
                return t('Vendor Login');
            case 'mfa-enable':
                return t('Enable Multi-factor Authentication');
            case 'mfa-code':
                return t('Multi-factor Authentication');
        }
    };

    const getContent = () => {
        if (selectRestaurant) {
            return <SelectRestaurant setSelectRestaurant={setSelectRestaurant} />;
        }

        switch (page) {
            default:
            case 'login':
                return (
                    <LoginComponent
                        loginAsAdmin={loginAsAdmin}
                        setLoginAsAdmin={setLoginAsAdmin}
                        accessSelectRestaurant={accessSelectRestaurant}
                        setLoginResponse={setLoginResponse}
                        setPage={setPage}
                        setError={setError}
                        loading={loading}
                        setLoading={setLoading}
                    />
                );
            case 'mfa-enable':
                return (
                    <Enable
                        loading={loading}
                        setLoading={setLoading}
                        setError={setError}
                        loginResponse={loginResponse}
                        loginAsAdmin={loginAsAdmin}
                        accessSelectRestaurant={accessSelectRestaurant}
                        setPage={setPage}
                    />
                );
            case 'mfa-code':
                return (
                    <Mfa
                        loading={loading}
                        accessSelectRestaurant={accessSelectRestaurant}
                        loginResponseData={loginResponse?.data}
                        setError={setError}
                        setLoading={setLoading}
                    />
                );
        }
    };
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                <LanguageSelector />
            </Box>

            <Container maxWidth="xs">
                <Grid container>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Logo height="50" style={{ margin: '2.2rem' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography color="primary" sx={TypographyProps} variant="h6">
                                    {getTitle()}
                                </Typography>
                                {getContent()}
                                {error && (
                                    <Typography sx={{ textAlign: 'center' }} color="error">
                                        {error}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Login;
