import { useRouter } from 'next/router';
import { LoginResponse } from '@/interfaces/auth/reponse';
import SSOLogin, { hasSSOLogin } from '@/views/Login/sso';
import PasswordLogin from '@/views/Login/password';
import { ILoginProps } from '@/views/Login/index';

export interface IBaseLoginProps extends ILoginProps {
    setLoginAsAdmin: (value: boolean) => void;
    setLoginResponse: (response: LoginResponse) => void;
    setPage: (page: 'login' | 'mfa-code' | 'mfa-enable') => void;
}

const LoginComponent = (props: IBaseLoginProps) => {
    const router = useRouter();
    const isSSO = hasSSOLogin(router.pathname);

    if (isSSO) {
        return <SSOLogin {...props} />;
    }

    return <PasswordLogin {...props} />;
};

export default LoginComponent;
