import type { SxProps } from '@mui/material';
import type { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { Field, type FieldProps } from 'formik';
import { ChangeEvent } from 'react';

export interface FormikTextFieldProps {
    name: string;
    label: string;
    size?: 'small' | 'medium';
    disabled?: boolean;
    helperText?: string;
    id?: string;
    InputProps?: Partial<OutlinedInputProps>;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    required?: boolean;
    sx?: SxProps;
    fullWidth?: boolean;
    type?: string;
    className?: string;
}

const FormikTextField = ({
    name,
    disabled,
    onChange,
    fullWidth = true,
    helperText,
    ...props
}: FormikTextFieldProps) => (
    <Field name={name}>
        {({ form: { setFieldValue, handleBlur, isSubmitting }, meta: { value, touched, error } }: FieldProps) => {
            return (
                <TextField
                    {...props}
                    fullWidth={fullWidth}
                    value={value}
                    disabled={disabled || isSubmitting}
                    error={touched && Boolean(error)}
                    helperText={helperText ?? (touched && error)}
                    onChange={(e) => {
                        setFieldValue(name, e.target.value);
                        onChange?.(e);
                    }}
                    onBlur={handleBlur(name)}
                />
            );
        }}
    </Field>
);

export default FormikTextField;
