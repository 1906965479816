import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-export-i18n';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Grid,
    ListItemText,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import { PrimaryButton } from '@/components/common';
import {
    accessTokenKey,
    chosenRestaurantIdKey,
    clearLocalStorage,
    restaurantPosVendorKey,
    userNameKey,
    userRoleKey,
} from '@/config/axios';
import { debounce } from 'lodash';
import RestaurantService from '@/services/restaurant';
import { ITinyRestaurant } from '@/views/OrdersTableView/types';
import { onPushEvent } from '@/services/lib/gtm';
import EventBus from '@/config/event-handler';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { SocketConnector } from '@/contexts/socket';

const DEBOUNCE_DELAY = 1000;

interface IProps {
    setSelectRestaurant: (value: boolean) => void;
}

const SelectRestaurant = ({ setSelectRestaurant }: IProps) => {
    const restaurantService = RestaurantService.getInstance();
    const { t } = useTranslation('common');
    const [selectedRestaurant, setSelectedRestaurant] = useState<ITinyRestaurant | null>(null);
    const { setUser } = useUserContext();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [choices, setChoices] = useState<ITinyRestaurant[]>([]);
    const { setRestaurant } = useRestaurantContext();
    const [open, setOpen] = useState(false);

    const abortControllerRef = useRef<AbortController | null>(null);

    const getRestaurantList = (val: string) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        setLoading(true);
        restaurantService
            .searchRestaurants({ search: val }, signal)
            .then((data) => {
                setChoices(data || []);
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    console.log('Request was aborted');
                } else {
                    setError(t('Something went wrong'));
                }
            })
            .finally(() => {
                setLoading(false);
                abortControllerRef.current = null;
            });
    };

    const debounceFn = useCallback(debounce(getRestaurantList, DEBOUNCE_DELAY), []);

    const handleOnChange = (ev: any) => {
        if (ev.target.value !== '' || ev.target.value !== null) {
            debounceFn(ev.target.value);
        }
    };

    useEffect(() => {
        getRestaurantList('');
        EventBus.dispatch('login');
    }, []);

    useEffect(() => {
        if (!open) {
            setLoading(false);
        }
    }, [open]);

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        const token = localStorage.getItem(accessTokenKey);
        if (token) {
            const name = localStorage.getItem(userNameKey) || '';
            const role = localStorage.getItem(userRoleKey) || '';

            if (selectedRestaurant) {
                localStorage.setItem(chosenRestaurantIdKey, selectedRestaurant.id);
                localStorage.setItem(restaurantPosVendorKey, selectedRestaurant?.pos_vendor_name || '');

                restaurantService
                    .getRestaurant(selectedRestaurant.id)
                    .then((data) => {
                        setRestaurant(data);
                    })
                    .catch(() => {
                        setError(t('something went wrong'));
                    })
                    .finally(() => setLoading(false));
                setUser({
                    ...jwtDecode(token),
                    restaurantId: selectedRestaurant.id,
                    posVendor: selectedRestaurant.pos_vendor_name,
                    userData: {
                        name,
                        role,
                    },
                });
            }
        }
    };
    const logout = () => {
        setUser(null);
        setRestaurant(null);
        SocketConnector.getInstance().destroy();
        clearLocalStorage();
        EventBus.dispatch('logout');
        onPushEvent('user_logged_out');
        setSelectRestaurant(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Autocomplete
                        options={choices}
                        filterOptions={(options) => options}
                        getOptionLabel={(option: ITinyRestaurant) => option.name || option.title || ''}
                        id="select-restaurant-autocomplete"
                        fullWidth
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        value={selectedRestaurant}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.id) {
                                setSelectedRestaurant(newValue);
                            }
                            if (event && event.type === 'click') {
                                onPushEvent('user_search_restaurant');
                            }
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('Vendor')}
                                variant="outlined"
                                onChange={handleOnChange}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <MenuItem {...props} selected={selected}>
                                <ListItemText
                                    data-test-class="restaurant-list-item"
                                    primary={option.title || option.name}
                                    secondary={option.restaurant_unique}
                                />
                            </MenuItem>
                        )}
                    />
                </Grid>
                {error && (
                    <Typography sx={{ textAlign: 'center' }} color="error">
                        {error}
                    </Typography>
                )}

                <Grid item xs={12}>
                    <PrimaryButton
                        id="select-res-btn"
                        type="submit"
                        text={t('Select')}
                        fullWidth
                        styles={{
                            marginTop: '1rem',
                            fontWeight: 700,
                            fontSize: '1rem',
                            borderRadius: '0.5rem',
                        }}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={6}>
                    <Button
                        id="logout-btn"
                        onClick={() => {
                            logout();
                        }}
                        variant="outlined"
                        size="medium"
                        startIcon={<KeyboardArrowLeft />}
                        fullWidth
                        sx={{
                            marginTop: '1rem',
                            textTransform: 'none',
                            fontSize: '1rem',
                            borderRadius: '0.5rem',
                            color: '#3B3B3B',
                            borderColor: '#3B3B3B',
                            ':hover': {
                                backgroundColor: '#A6A6A6',
                                color: '#fff',
                                borderColor: '#3B3B3B',
                            },
                        }}
                        disableElevation
                    >
                        {t('Logout')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default SelectRestaurant;
