import { useState } from 'react';
import { useFormikContext } from 'formik';

import type { DefaultComponentProps } from '@mui/material/OverridableComponent';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FormikTextField, { FormikTextFieldProps } from '../FormikTextField';
import { useEntryCondition, useEntryText } from './hooks';

const listItemIconProps: DefaultComponentProps<SvgIconTypeMap<Record<string, unknown>, 'svg'>> = {
    fontSize: 'small',
    sx: { marginBottom: '-4px' },
};

interface FormikPasswordFieldProps extends FormikTextFieldProps {
    requirements?: {
        max?: number;
        min?: number;
        number?: boolean;
        smallcase?: boolean;
        specialCharacter?: boolean;
        uppercase?: boolean;
    };
}

const FormikPasswordField = ({ requirements, ...props }: FormikPasswordFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState<string>('');

    const { getEntryText } = useEntryText();
    const { checkEntryConditionFullfilled } = useEntryCondition(value);

    const entries = Object.entries(requirements || {});

    const { errors, touched } = useFormikContext();
    // @ts-ignore
    const isDirty = touched[props.name] && Boolean(errors[props.name]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormikTextField
                    {...props}
                    helperText={entries.length > 0 ? '' : undefined}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setValue(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                />
            </Grid>
            {entries.length > 0 && (
                <Grid item xs={12}>
                    <List dense>
                        {entries.map((entry) => (
                            <ListItem key={entry[0]} disableGutters disablePadding>
                                <ListItemAvatar sx={{ minWidth: 25 }}>
                                    {checkEntryConditionFullfilled(entry) ? (
                                        <Check {...listItemIconProps} color="success" />
                                    ) : isDirty ? (
                                        <Clear {...listItemIconProps} color="error" />
                                    ) : // <Brightness1 {...listItemIconProps} color="info" />
                                    null}
                                </ListItemAvatar>
                                <ListItemText primary={getEntryText(entry)} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            )}
        </Grid>
    );
};

export default FormikPasswordField;
